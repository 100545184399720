import { addMethod, string, StringSchema } from "yup";

export default defineNuxtPlugin(() => {
  const { getFilter, clientAppInit } = useGeneralStore();

  addMethod(string, "email", function () {
    return this.matches(
      /^\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b$/,
      {
        name: "email",
        message: (path) => `${path.label} must be a valid email`,
        excludeEmptyString: true,
      },
    );
  });

  addMethod<StringSchema>(
    string,
    "fieldNotExists",
    function (arrToCheck, message) {
      function getMessage(path, message) {
        return (
          message ||
          `Incorrect ${path.label.toLowerCase()}: The ${path.label.toLowerCase()} does not correspond with the other fields`
        );
      }
      return this.test(
        "fieldNotExists",
        (path) => getMessage(path, message),
        function () {
          const { path, createError } = this;
          return (
            (arrToCheck &&
              Array.isArray(arrToCheck) &&
              !arrToCheck.includes(path)) ||
            createError()
          );
        },
      );
    },
  );

  const route = useRoute();
  const { cartUniqueId } = useCookiesService();

  let getCartByCartID = false; // TODO refactor it
  const cartUniqueIdRoutes = ["checkout", "cart"];
  if (
    cartUniqueIdRoutes.some((path) => route.path.split("/").includes(path)) &&
    route.params.id &&
    route.params.id !== cartUniqueId.value
  ) {
    cartUniqueId.value = route.params.id as string;
    getCartByCartID = true;
  }

  nextTick(async () => {
    await Promise.all([
      getFilter(),
      clientAppInit({ cartUniqueId, getCartByCartID }),
    ]);
  });
});
